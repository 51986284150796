<template>
  <div>
    <h2>老年人能力评估表 - B.4 感知觉与社会参与评估表</h2>

    <div v-for="(question, index) in questions" :key="index" class="question-section">
      <h3>{{ question.title }}</h3>
      <div v-for="(option, idx) in question.options" :key="idx" class="option">
        <label>
          <input
            type="radio"
            :name="'question' + index"
            :value="option.score"
            v-model="question.selectedScore"
          >
          {{ option.text }}
        </label>
      </div>
    </div>

    <div class="total-score">
      <h3>总计得分: {{ totalScore }}</h3>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      questions: [
        {
          title: "B.4.1 视力：感受存在的光线并感受物体的大小、形状能力。在个体最好矫正视力下进行评估",
          selectedScore: 2,
          options: [
            { text: "视力正常", score: 2 },
            { text: "能看清楚大字体，但看不清书报上的标准字体；视力有限，看不清报纸大标题，但能辨认物体", score: 1 },
            { text: "只能看到光、颜色和形状；完全失明", score: 0 },
          ],
        },
        {
          title: "B.4.2 听力：能辨别声音的方位、音调、音量和音质的有关能力（可借助平时使用助听设备等）",
          selectedScore: 2,
          options: [
            { text: "听力正常", score: 2 },
            { text: "在轻声说话或说话距离超过2米时听不清；正常交流有些困难，需在安静的环境或大声说话才能听到", score: 1 },
            { text: "讲话者大声说话或说话很慢，才能部分听见；完全失聪", score: 0 },
          ],
        },
        {
          title: "B.4.3 执行日常事务：计划、安排并完成日常事务，包括但不限于洗衣服、小金额购物、服药管理",
          selectedScore: 4,
          options: [
            { text: "能完全独立计划、安排和完成日常事务，无需协助", score: 4 },
            { text: "在计划、安排和完成日常事务时需要他人监护或指导", score: 3 },
            { text: "在计划、安排和完成日常事务时需要少量协助", score: 2 },
            { text: "在计划、安排和完成日常事务时需要大量协助", score: 1 },
            { text: "完全依赖他人进行日常事务", score: 0 },
          ],
        },
        {
          title: "B.4.4 使用交通工具外出",
          selectedScore: 3,
          options: [
            { text: "能自己骑车或搭乘公共交通工具外出", score: 3 },
            { text: "能自己搭乘出租车，但不会搭乘其他公共交通工具外出", score: 2 },
            { text: "当有人协助或陪伴，可搭乘公共交通工具外出", score: 1 },
            { text: "只能在他人协助下搭乘出租车或私家车外出；完全不能出门，或者外出完全需要协助", score: 0 },
          ],
        },
        {
          title: "B.4.5 社会交往能力",
          selectedScore: 4,
          options: [
            { text: "参与社会，在社会环境有一定的适应能力，待人接物恰当", score: 4 },
            { text: "能适应单纯环境，主动接触他人，初见面时难让人发现智力问题，不能理解隐喻语", score: 3 },
            { text: "脱离社会，可被动接触，不会主动待他人，谈话中很多不适词句，容易上当受骗", score: 2 },
            { text: "勉强可与他人接触，谈吐内容不清楚，表情不恰当", score: 1 },
            { text: "不能与人交往", score: 0 },
          ],
        },
      ],
    };
  },
  computed: {

    sensorySocialScore: {
      get() { return this.$store.state.home_older.sensorySocialScore },
      set(val) { this.$store.commit('home_older/updateSensorySocialScore', val) }
    },

    totalScore() {
      this.sensorySocialScore = this.questions.map(question => question.selectedScore);
      return this.questions.reduce((sum, question) => sum + question.selectedScore, 0);
    },
  },
};
</script>

<style scoped>
.question-section {
  margin-bottom: 20px;
}

.option {
  margin-left: 20px;
}

.total-score {
  margin-top: 30px;
  font-weight: bold;
}
</style>
